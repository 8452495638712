<script setup>
import { useRouter } from 'nuxt/app';
import { useAuthenticationStore } from '~/stores/account/authentication.store';

const { $toast } = useNuxtApp()



const authStore = useAuthenticationStore();
const router = useRouter();
const localePath = useLocalePath()

const email = ref('');
const password = ref('');

const passwordVisible = ref(false)

const showPassword = async () => {
  passwordVisible.value = !passwordVisible.value;
}

const login = async () =>  {
  await authStore.login({email: email.value, password: password.value})
  .then((res) => {
    router.push({ path: localePath({ name: 'more' }) });
  })
  .catch((err) => {
    const message = err?.response?.data?.error || 'Something Went Wrong Please Try Again'
    $toast.addToast({ title: message, color: "red", position: 'bottom-10' })
  })
};
</script>

<template>
  <NuxtLayout name="mobile-base">
    <template #mobile-header>
      <MobileHeader title="Login">
        <template #header-left>
          <mobile-back-button></mobile-back-button>
        </template>
      </MobileHeader>
      <div class="relative">
          <main class="mb-32 px-4">
              <div class="flex flex-col justify-center my-10 w-full text-center items-center">
                  <img :src="`images/${appId}/logo-blue.webp`" alt="" class="w-44">
                  <p class="mt-3 text-gray-500 text-xl">{{ $t('client.sign_in_to_continue') }}</p>
              </div>
              <form action="" class="*:mb-3">
                  <div>
                      <input 
                        v-model="email"
                        type="email" 
                        placeholder="Email"
                        class="block w-full ring-1 ring-gray-300 ring-inset shadow-sm text-gray-900 text-sm p-3 rounded-md"
                      />
                  </div>
                  <div class="relative">
                      <input 
                        v-model="password"
                        :type="passwordVisible ? 'text' : 'password'"
                        placeholder="Password"
                        class=" block w-full ring-1 ring-gray-300 ring-inset shadow-sm text-gray-900 text-sm p-3 rounded-md"
                      />
                      <div @click="showPassword" class="absolute right-3 top-1/2 -translate-y-1/2 cursor-pointer">
                        <i :class="passwordVisible ? 'fa-solid fa-eye-slash' : 'fa-solid fa-eye'" class="text-gray-500"></i>
                      </div>
                      <NuxtLink
                        :to="localePath({ name: 'account.forget.password' })" 
                        class="text-sm text-indigo-900 absolute right-0 -bottom-8"
                      >
                        {{ $t('client.forget_password_q') }}
                      </NuxtLink>
                  </div>
                  <div @click="login" class="mt-16 w-full flex">
                      <a class="bg-indigo-900 text-white rounded-lg p-3 capitalize  w-full text-center">
                        {{ $t('client.login') }}
                      </a>
                  </div>
                  <div class="mt-40 flex justify-center items-center ">
                      <NuxtLink :to="localePath({ name: 'account.register' })" class="w-full m-auto text-center text-indigo-900">
                        {{ $t('client.dont_have_an_account') }}
                        <span class="font-bold">{{ $t('client.create_one') }}</span> 
                      </NuxtLink>
                  </div>
              </form>
          </main>
      </div>
      
    </template>

    <template #mobile-footer></template>
  </NuxtLayout>
</template>